import {Link, useLocation } from 'react-router-dom'
import React from 'react';


const Navbar = () => {
    const location = useLocation();

    // Determine the destination and text based on the current location
    let linkText, destination, linkText2, destination2;
    if (location.pathname === '/info') {
        destination = '/';
        linkText = 'Home';
        destination2 = '/events';
        linkText2 = 'Events';
    } else if (location.pathname === '/'){
        destination2 = '/info';
        linkText2 = 'Info';
        destination = '/events';
        linkText = 'Events';
    } else if (location.pathname === '/events'){
        destination2 = '/info';
        linkText2 = 'Info';
        destination = '/';
        linkText = 'Home';
    }

    return (
        <nav className="navbar">
            <div className="links">
                <Link to={destination}>{linkText}</Link>
                <Link to={destination2}>{linkText2}</Link>


            </div>
        </nav>
    );
}
 
export default Navbar ;

