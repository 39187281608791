//App.js

import Navbar from './Navbar';
import Home, {HomeContent} from './Home';
import Events, {EventsContent} from './Events';

import Create from './Create';
import { BrowserRouter as Router, Routes, Route, HashRouter, useLocation } from 'react-router-dom';
import { Suspense, useState, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';  
import BlogDetails from './BlogDetails';
import NotFound from './NotFound';
import React from 'react';
import Info, {InfoContent} from './Info';
import SceneOne from './SceneOne';



function AppCanvas() {
  const location = useLocation();
  const [scene, setScene] = useState(null);

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setScene(<Home />);
        break;
      case "/info":
          setScene(<Info />);
          break;
      case "/events":
            setScene(<Events />);
            break;
    
      default:
        setScene(<Home />);
        break;
    }
  }, [location.pathname]);

  return <Canvas style={{ height: '100vh' }}>{scene}</Canvas>;
}

function ContentCanvas() {
  const location = useLocation();
  const [content, setContent] = useState(null);

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        console.log("Home")
        setContent(<HomeContent />);
        break;
      case "/info":
        console.log("Info")
        setContent(<InfoContent />);
        break;
      case "/events":
        console.log("Info")
        setContent(<EventsContent />);
        break;
      default:
        console.log("default")
        setContent(<HomeContent />);
        break;
    }
  }, [location.pathname]);

  // Just return the content, no Canvas needed for HTML
  return content;
}




function App() {
  return (
    <HashRouter>
      <div className="App">
        <Navbar />
        <div className="content fullscreen"> {/* Added 'fullscreen' class */}
          <Suspense fallback={null}>
            <AppCanvas />
            <ContentCanvas/>
          </Suspense>
        </div>
      </div>
    </HashRouter>
  );
}

export default App;
