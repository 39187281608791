import React, { useRef, useState, useEffect } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';

const CameraRig = ({ 
    zoom = false, 
    zoomRange = [20, 80], 
    rotationRange = { horizontal: 20, vertical: 20 },
    zoomSmoothing = 0.1, 
    cameraSmoothing =  .1,
    cameraSmoothingMobile = 1, 

    resetOnMouseLeave = false 
}) => {
    const rig = useRef();
    const { camera, gl } = useThree();
    const [isMobile, setIsMobile] = useState(false);
    const [targetFov, setTargetFov] = useState(camera.fov);
    const [targetRotation, setTargetRotation] = useState({ x: 0, y: 0 });
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });



//Desktop updates
        // Update the mouse position
        const onMouseMove = (event) => {
            const mouseX = (event.clientX / window.innerWidth) * 2 - 1;
            const mouseY = -(event.clientY / window.innerHeight) * 2 + 1;
            setMousePosition({ x: mouseX, y: mouseY });

            // Calculate and clamp target rotation
            const targetXRotation = THREE.MathUtils.clamp(mouseY * rotationRange.vertical, -rotationRange.vertical, rotationRange.vertical);
            const targetYRotation = THREE.MathUtils.clamp(mouseX * -rotationRange.horizontal, -rotationRange.horizontal, rotationRange.horizontal);
            setTargetRotation({ x: targetXRotation, y: targetYRotation });
        };


        // Reset mouse position when the mouse leaves the canvas
        const onMouseLeave = () => {
            if (resetOnMouseLeave) {
                setMousePosition({ x: 0, y: 0 });
                setTargetRotation({ x: 0, y: 0 });
            }
        };

        // Handle mouse wheel zoom
        const onWheel = (event) => {
            if (zoom) {
                event.preventDefault();
                const delta = event.deltaY;
                const newTargetFov = THREE.MathUtils.clamp(
                    targetFov + delta * 0.05,
                    zoomRange[0],
                    zoomRange[1]
                );
                setTargetFov(newTargetFov);
            }
        };

//Mobile updates    /
const handleDeviceOrientation = (event) => {
            const { beta, gamma } = event;
            // Convert degrees to target rotation within bounds
            // const betaRotation = THREE.MathUtils.clamp(THREE.MathUtils.degToRad((beta + 90) * -.5), -rotationRange.vertical, rotationRange.vertical);
            // const gammaRotation = THREE.MathUtils.clamp(THREE.MathUtils.degToRad(gamma * -.5), -rotationRange.horizontal, rotationRange.horizontal);
            const betaRotation = THREE.MathUtils.degToRad((beta-55)*-.5);
            const gammaRotation = THREE.MathUtils.degToRad(gamma*-.5);
            setTargetRotation({ x: betaRotation*15, y: gammaRotation*15 });
        };



// Detect if device is mobile
        useEffect(() => {
            const checkIfMobile = () => {
                // Simple mobile detection
                const userAgent = navigator.userAgent || navigator.vendor || window.opera;
                return /android|ipad|iphone|ipod/i.test(userAgent);
            };
            setIsMobile(checkIfMobile());
        }, []);
        

// Handle device orientation or mouse movement
        useEffect(() => {


            const canvas = gl.domElement;
            // Set the camera position relative to the rig
            if (rig.current) {
                camera.position.set(0, 0, 5);
                camera.lookAt(0, 0, 0);
                rig.current.add(camera);
            }
            const handleWheel = (event) => {
                if (zoom) {
                    event.preventDefault();
                    const delta = event.deltaY;
                    const newTargetFov = THREE.MathUtils.clamp(
                        targetFov + delta * 0.05,
                        zoomRange[0],
                        zoomRange[1]
                    );
                    setTargetFov(newTargetFov);
                }
            };

            if (isMobile) {
                window.addEventListener('deviceorientation', handleDeviceOrientation, true);
                console.log('Mobile')
            } else {
                console.log('Desktop')
                canvas.addEventListener('mousemove', onMouseMove);
                canvas.addEventListener('wheel', handleWheel, { passive: false });
                canvas.addEventListener('mouseleave', onMouseLeave);
            }

            return () => {
                if (isMobile) {
                    window.removeEventListener('deviceorientation', handleDeviceOrientation, true);
                } else {
                    canvas.removeEventListener('mousemove', onMouseMove);
                    canvas.removeEventListener('wheel', handleWheel);
                    canvas.removeEventListener('mouseleave', onMouseLeave);
                }
            };
        }, [isMobile, camera, gl.domElement,  targetFov, zoom]);



// Rotate the rig and smoothly update the FOV
        useFrame(() => {
            if (rig.current) {
                // Smoothly interpolate the camera's FOV
                camera.fov = THREE.MathUtils.lerp(camera.fov, targetFov, zoomSmoothing);
                camera.updateProjectionMatrix();

                const smoothing = isMobile ? cameraSmoothingMobile : cameraSmoothing  ;


                // Smoothly interpolate the rig's rotation
                rig.current.rotation.x = THREE.MathUtils.lerp(rig.current.rotation.x, THREE.MathUtils.degToRad(targetRotation.x), smoothing);
                rig.current.rotation.y = THREE.MathUtils.lerp(rig.current.rotation.y, THREE.MathUtils.degToRad(targetRotation.y), smoothing);
            }
        });

    return <group ref={rig} />;
};

export default CameraRig;
