/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 public\objects.glb 
*/

import React, { useRef } from 'react'
import { useGLTF, MeshTransmissionMaterial } from '@react-three/drei'
import * as THREE from 'three';

export function Objects(props) {
  const { nodes, materials } = useGLTF('/objects.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Sphere.geometry} material={nodes.Sphere.material} position={[0, 0, 0]}>
      <MeshTransmissionMaterial
                        clearcoat={1}
                        clearcoatRoughness={0}
                        transmission={1}
                        chromaticAberration={0.1}
                        anisotrophicBlur={0.1}
                        roughness={0}
                        thickness={5}
                        reflectivity={0}
                        ior={1.4}
                        distortion={0.1}
                        distortionScale={0.2}
                        temporalDistortion={0.2}
                        background={new THREE.Color(0xffffff)}/>
        </mesh>
    </group>
  )
}

useGLTF.preload('/objects.glb')
