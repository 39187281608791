import { useState, useEffect } from "react";

const useFetch = (url) => {
    const [data, setData] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const [isError, setIsError] = useState(null);

    useEffect(()=>{
        const abortCont = new AbortController();
            fetch(url, {signal: abortCont.signal})
            .then(res => {
                //If the response is not ok, throw an error
                if(!res.ok){
                    throw Error('Could not fetch the data for that resource.');
                }
                return res.json();
            })
            .then(data => {
                setData(data);
                setIsPending(false);
                setIsError(null);
            })
            .catch(err => {
                if(err.name === 'AbortError'){
                    console.log('fetch aborted');
                } else {
                setIsError(err.message);
                setIsPending(false);
                console.log(err.message);
                }
            })
        return () => {
            console.log('cleanup')
            abortCont.abort();
            };


    },[url]);

    return {data, isPending, isError};
}


export default useFetch;