//SceneOne.jsx

import React, { useRef, Suspense, useEffect, useMemo, useState, useCallback } from 'react';
import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber';
import { TextureLoader, Raycaster, Vector2, Vector3,MeshBasicMaterial, MeshStandardMaterial, AudioLoader, AudioListener, AudioAnalyser, VideoTexture   } from 'three';
import { Box, OrbitControls, useGLTF, MeshTransmissionMaterial, MeshRefractionMaterial, useAnimations,  PositionalAudio  } from '@react-three/drei';
import { createRoot } from 'react-dom/client';
import { RGBELoader } from 'three-stdlib'
import * as THREE from 'three';
import { suspend } from 'suspend-react'
import Hls from 'hls.js';

// This should be in your main entry JS or JSX file
import ReactDOM from 'react-dom';
import './app.css';
import { Mesh } from 'three';

// Get the div with the id of 'app'
// const container = document.getElementById('app');

// Create a root
// const root = createRoot(container);

const raycaster = new Raycaster();
const mouse = new Vector2();






function Track({ url, y = 2500, space = 1.8, width = 0.01, height = 0.05, obj = new THREE.Object3D(), ...props }) {
  const ref = useRef()
  // suspend-react is the library that r3f uses internally for useLoader. It caches promises and
  // integrates them with React suspense. You can use it as-is with or without r3f.
  const { gain, context, update, data } = suspend(() => createAudio(url), [url])
  useEffect(() => {
    // Connect the gain node, which plays the audio
    gain.connect(context.destination)
    // Disconnect it on unmount
    return () => gain.disconnect()
  }, [gain, context])

  useFrame((state) => {
    let avg = update()
    // Distribute the instanced planes according to the frequency daza
    for (let i = 0; i < data.length; i++) {
      obj.position.set(i * width * space - (data.length * width * space) / 2, data[i]*2 / y, 0)
      obj.updateMatrix()
      ref.current.setMatrixAt(i, obj.matrix)
    }
    // Set the hue according to the frequency average
    ref.current.material.color.setHSL(avg / 500, 0.75, 0.75)
    ref.current.instanceMatrix.needsUpdate = true
  })
  return (
    <instancedMesh castShadow ref={ref} args={[null, null, data.length]} {...props}>
      <sphereGeometry args={[width, height]} />
      <meshBasicMaterial toneMapped={false} />
    </instancedMesh>
  )
}

function TrackSphere({ url, y = 2500, scale = 1, ...props }) {
  const ref = useRef()
  const { gain, context, update, data } = suspend(() => createAudio(url), [url])
  useEffect(() => {
    gain.connect(context.destination)
    return () => gain.disconnect()
  }, [gain, context])

  useFrame(() => {
    let avg = update()
    const sphereScale = avg / 500 * scale
    ref.current.scale.set(sphereScale, sphereScale, sphereScale)
  })

  return (
    <mesh ref={ref} {...props}>
      <sphereGeometry args={[1, 32, 32]} />
      <MeshTransmissionMaterial  thickness={.5} temporalDistortion={.1} />
      {/* <meshBasicMaterial toneMapped={false} color="black" /> */}
    </mesh>
  )
}

function VideoPlane({ videoPath, width = 1.224, height = 1.024 }) {
  const meshRef = useRef();
  const videoRef = useRef(document.createElement('video'));

  // Set video properties
  videoRef.current.src = videoPath;
  videoRef.current.crossOrigin = 'anonymous';
  videoRef.current.loop = true;
  videoRef.current.muted = true;
  videoRef.current.playsInline = true;

  // Load the video as a texture
  const texture = new THREE.VideoTexture(videoRef.current);

  // Play the video when the component mounts
  useEffect(() => {
    const playPromise = videoRef.current.play();
  
    if (playPromise !== undefined) {
      playPromise.then(() => {
        // Video playback started successfully
      })
      .catch(error => {
        // Video playback failed
        console.error("Video playback failed", error);
      });
    }
  }, [videoPath]);
  

  // Update the texture when the video plays
  useThree(({ gl }) => {
    gl.texture = texture;
  });

  return (
    <mesh ref={meshRef} rotation={[Math.PI / -2, 0, 0]} position={[0,-.1,-0.6]}> {/* Rotate the video 90 degrees */}
      <planeGeometry args={[width, height]}/>
      <meshBasicMaterial map={texture} />
      {/* The video element is not directly rendered in the DOM tree */}
    </mesh>
  );
}

function VideoStreamPlane({ videoPath, width = 1.224, height = 1.024 }) {
  const meshRef = useRef();
  const { camera } = useThree();
  const videoRef = useRef(document.createElement('video'));
  let hls;
  let audioData;

  useEffect(() => {
    // Check if HLS is supported or if the browser can play HLS natively
    if (Hls.isSupported()) {
      hls = new Hls();
      hls.loadSource(videoPath);
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        videoRef.current.play();
      });
    } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
      videoRef.current.src = videoPath;
      videoRef.current.play();
    }

    // Use the `createAudio` function to set up the audio for the video
    (async () => {
      audioData = await createAudio(videoPath);
      videoRef.current.onplay = () => {
        audioData.source.connect(audioData.gain);
      };
      videoRef.current.onpause = videoRef.current.onended = () => {
        audioData.source.disconnect();
      };
    })();

    // Cleanup function
    return () => {
      if (hls) {
        hls.destroy();
      }
      if (audioData) {
        audioData.source.disconnect();
      }
    };
  }, [videoPath]);

  // Load the video as a texture
  const texture = new THREE.VideoTexture(videoRef.current);

  return (
    <mesh ref={meshRef} rotation={[Math.PI / -2, 0, 0]} position={[0, -0.1, .6]}>
      <planeGeometry args={[width, height]} />
      <meshBasicMaterial map={texture} />
    </mesh>
  );
}

function TrackSphereColor({ url, y = 2500, scale = 1, ...props }) {
  const ref = useRef()
  const { gain, context, update, data } = suspend(() => createAudio(url), [url])
  useEffect(() => {
    gain.connect(context.destination)
    return () => gain.disconnect()
  }, [gain, context])

  useFrame(() => {
    let avg = update()
    const sphereScale = avg / 500 * scale
    const color = `rgb(${Math.floor(avg)*4}, ${Math.floor(avg)*4}, ${Math.floor(avg)*4})`
    ref.current.scale.set(sphereScale, sphereScale, sphereScale)
    ref.current.material.color.setStyle(color)
  })

  return (
    <mesh ref={ref} {...props}>
      <sphereGeometry args={[1, 32, 32]} />
      <meshBasicMaterial toneMapped={false} color="black" />
    </mesh>
  )
}

function Zoom({ url }) {
  // This will *not* re-create a new audio source, suspense is always cached,
  // so this will just access (or create and then cache) the source according to the url
  const { data } = suspend(() => createAudio(url), [url])
  return useFrame((state) => {
    // Set the cameras field of view according to the frequency average
    state.camera.fov = 25 - data.avg / 15
    state.camera.updateProjectionMatrix()
  })
}

async function createAudio(url) {
  // Fetch audio data and create a buffer source
  const res = await fetch(url)
  const buffer = await res.arrayBuffer()
  const context = new (window.AudioContext || window.webkitAudioContext)()
  const source = context.createBufferSource()
  source.buffer = await new Promise((res) => context.decodeAudioData(buffer, res))
  source.loop = true
  // This is why it doesn't run in Safari 🍏🐛. Start has to be called in an onClick event
  // which makes it too awkward for a little demo since you need to load the async data first
  source.start(0)
  // Create gain node and an analyser
  const gain = context.createGain()
  const analyser = context.createAnalyser()
  analyser.fftSize = 64
  source.connect(analyser)
  analyser.connect(gain)
  // The data array receive the audio frequencies
  const data = new Uint8Array(analyser.frequencyBinCount)
  return {
    context,
    source,
    gain,
    data,
    // This function gets called every frame per audio source
    update: () => {
      analyser.getByteFrequencyData(data)
      // Calculate a frequency average
      return (data.avg = data.reduce((prev, cur) => prev + cur / data.length, 0))
    },
  }
}

// Assuming createAudioSpatial is a function you have which sets up the audio context
// It should NOT start the audio by itself since PositionalAudio will do that
async function createAudioSpatial(url) {
  // Fetch audio data and create a buffer source
  const res = await fetch(url);
  const buffer = await res.arrayBuffer();
  const context = new (window.AudioContext || window.webkitAudioContext)();
  const source = context.createBufferSource();
  await context.decodeAudioData(buffer, decodedData => {
    source.buffer = decodedData;
  });
  source.loop = true;

  // Create gain node and an analyser
  const gain = context.createGain();
  const analyser = context.createAnalyser();
  analyser.fftSize = 64;
  source.connect(analyser);
  analyser.connect(gain);
  gain.connect(context.destination); // Connect the gain to the context's destination

  const data = new Uint8Array(analyser.frequencyBinCount);
  return {
    context,
    source,
    gain,
    data,
    analyser,
    update: () => {
      analyser.getByteFrequencyData(data);
      return data.reduce((prev, cur) => prev + cur / data.length, 0);
    },
  };
}

function TrackSphereSpatial({ url, y = 2500, scale = 1, ...props }) {
  const ref = useRef();
  const sound = useRef();
  const { camera } = useThree(); // Get the camera from the R3F context
  const [listener] = useState(() => new THREE.AudioListener()); // Create an audio listener
  const [buffer, setBuffer] = useState(null); // State to hold the audio buffer

  // Use an effect for loading the audio with AudioLoader
  useEffect(() => {
    const audioLoader = new THREE.AudioLoader();
    audioLoader.load(url, (buf) => {
      setBuffer(buf); // Set the loaded buffer
    });
  }, [url]);

  useEffect(() => {
    if (buffer) {
      sound.current.setBuffer(buffer); // Set the buffer to the positional audio
      sound.current.setRefDistance(1); // Set the reference distance for attenuation
      sound.current.setLoop(true); // Set it to loop
      sound.current.play(); // Play the sound
      camera.add(listener); // Add the listener to the camera
    }

    return () => {
      if (sound.current) {
        sound.current.stop(); // Stop the sound on unmount
      }
      camera.remove(listener); // Remove the listener from the camera
    };
  }, [buffer, camera, listener]);

  useFrame(() => {
    let avg = 0; // You will need to calculate this based on your audio analysis
    const sphereScale = avg / 500 * scale;
    ref.current.scale.set(sphereScale, sphereScale, sphereScale);
  });

  return (
    <mesh ref={ref} {...props}>
      <sphereGeometry args={[1, 32, 32]} />
      <MeshTransmissionMaterial thickness={0.5} temporalDistortion={0.1} />
      {/* Attach the PositionalAudio component */}
      <PositionalAudio ref={sound} args={[listener]} />
    </mesh>
  );
}


// Initial render: Render the App component to the root
// root.render(<App />);


const Text2 = () => {
  return ( 
    <div>
    <p>HI</p>

    </div>

   );
}
 


function SceneOne() {
  const cameraRef = useRef();

  const handleMouseMove = (event) => {
    console.log("Mouse coordinates:", event.clientX, event.clientY);
  };

  const [audioStarted, setAudioStarted] = useState(false);
  const [isInteracted, setIsInteracted] = useState(false);
  // This function will start the audio context and any other media that requires user interaction
  const handleUserInteraction  = () => {
    // This is a workaround for resuming the audio context on user interaction
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    const resumePromise = audioCtx.resume();

    if (resumePromise !== undefined) {
      resumePromise.then(() => {
        // Audio context is resumed, you can now start audio sources
        setAudioStarted(true);
        setIsInteracted(true);

      }).catch((error) => {
        console.log("Audio Context resume failed: ", error);
      });
    }
  };


  return (
    <>
    <Canvas >
      <Suspense fallback={null}>

      <color attach="background" args={['black']} />

      <spotLight position={[-4, 4, -4]} angle={0.06} penumbra={1} castShadow shadow-mapSize={[2048, 2048]} />
      
        <VideoPlane videoPath="vid.mp4" />
        {/* <VideoStreamPlane videoPath="https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8" /> */}
        {/* <TrackSphereSpatial position-z={-0.25} url="indeterminate.mp3" /> */}
        <Track position-z={0.25} url="indeterminate.mp3" />
        <TrackSphere position-z={0.25} url="indeterminate.mp3" />
        <TrackSphereColor position-z={0} url="indeterminate.mp3" />
        {/* <Zoom url="indeterminate.mp3" /> */}
     
      <mesh receiveShadow rotation={[-Math.PI / 2, 0, 0]} position={[0, -0.025, 0]}>
        <planeGeometry />
        <shadowMaterial transparent opacity={0.15} />
      </mesh>
      <OrbitControls />
      {/* <CameraWithAccelerometer  /> */}
      </Suspense>
    </Canvas>

    <div>
      <h1> THIS IS THE FUTURE</h1>
    <p>HI</p>
    <p>The code snippet you provided is a part of a React component called CameraWithAccelerometer. This component utilizes the useThree hook from the @react-three/fiber library to access the camera object. It also uses the useEffect hook to handle the device orientation event and update the rotation of an object based on the alpha, beta, and gamma values obtained from the event. The rotation is applied to the objectRef using the rotation.set method from the three library. This allows for a dynamic and interactive camera experience based on the device's orientation.</p>
    </div>

    </>

    )}





function CameraWithAccelerometer() {
  const { camera } = useThree();
  const objectRef = useRef();

  useEffect(() => {
    const handleDeviceOrientation = (event) => {
      const { alpha, beta, gamma } = event;

      console.log('Orientation values:', alpha, beta, gamma);

      if (objectRef.current) {
        // Converting degrees to radians
        const alphaRadians = THREE.MathUtils.degToRad(alpha);
        const betaRadians = THREE.MathUtils.degToRad((beta+90)*-.5);
        const gammaRadians = THREE.MathUtils.degToRad(gamma*-.5);

        // Applying the rotation to the object
        objectRef.current.rotation.set(betaRadians,gammaRadians, 0);

        console.log('Object rotation set to:', objectRef.current.rotation);
      }
    };

    window.addEventListener('deviceorientation', handleDeviceOrientation, true);

    return () => {
      window.removeEventListener('deviceorientation', handleDeviceOrientation, true);
    };
  }, []);

  return (
    <group ref={objectRef}>
      <primitive object={camera} />
    </group>
  );
}


export default SceneOne;
