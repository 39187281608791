import BlogList from './Bloglist';
import useFetch from './useFetch';
import { Canvas, useLoader, useFrame, meshBasicMaterial  } from '@react-three/fiber';
import { Box, MeshPortalMaterial, MeshTransmissionMaterial, MeshRefractionMaterial, MeshWobbleMaterial, OrbitControls, Environment, Reflector, Text, Plane, Cone, useFBO, useGLTF} from '@react-three/drei';
import { useEffect, useRef, useState, Suspense } from 'react';
import * as THREE from 'three';
import { TextureLoader } from 'three';
import CameraRig from './CameraRig';
import { RGBELoader } from 'three-stdlib'

import { ToneMapping, EffectComposer, Bloom } from '@react-three/postprocessing'
import { BlendFunction,  KernelSize, Resolution,BlurPass, Resizer  } from 'postprocessing'

import Objects from './Objects';

export const InfoContent = () => {
    return(
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', userSelect: 'none' }}>
    
    <a href="https://maps.google.com/?q=505+Hamilton,+Vancouver,+BC,+Canada" style={{ textAlign: 'center', color:"black", textDecoration: 'underline' }}>
        <p>505 Hamilton</p>
        <p>Vancouver, BC</p>
        <p>Canada</p>
    </a>

    <div style={{ height: '20px', width: '20px' }}></div>

    <p style={{ textAlign: 'center', color:"black", maxWidth: 200 }}>A shared studio space catering to independent creatives focused on 3D rendering, motion design, interactive media, and XR. It provides a low-key, supportive environment where professionals converge, bringing dedication to their respective fields. </p>
    <div style={{ height: '20px', width: '20px' }}/>
    <div style={{ textAlign: 'center', color:"black" }}>
    <a href="mailto:z.space.vancouver@gmail.com">Inquire</a>
    </div>
</div>
    )
}





const Info = () => {
    const { data: blogs, isPending, isError } = useFetch('https://meta-blog-json.onrender.com/blogs');
    const [rotation, setRotation] = useState(0);


    const texture = useLoader(RGBELoader, "sky.hdr")
    const white = useLoader(TextureLoader, 'white.png');
    const normalMap = useLoader(TextureLoader, '315-normal.jpg');
    normalMap.wrapS = THREE.RepeatWrapping;
    normalMap.wrapT = THREE.RepeatWrapping;
    normalMap.repeat.set(2, 2); // Adjust the repetition to your liking



    


    // ...


    

    const { nodes, materials } = useGLTF('./objects.glb')

    return (

            <>
                <color attach="background" args={['white']} />    
                   <Suspense fallback={null}>
                   <Environment files="sky.hdr" />
                   <pointLight intensity={1} position={[0,1,0]} />
                     <ambientLight intensity={.1} />
  
                    <mesh geometry={nodes.Sphere.geometry} scale={[0.35, 0.35, 0.35]} rotation={[Math.PI / 2, Math.PI/4, 0]}>
                     <MeshTransmissionMaterial
                        clearcoat={1}
                        clearcoatRoughness={0}
                        transmission={1}
                        chromaticAberration={0.1}
                        anisotrophicBlur={0.1}
                        roughness={0}
                        thickness={2}
                        reflectivity={0}
                        ior={1.4}
                        distortion={0.1}
                        distortionScale={0.2}
                        temporalDistortion={0.2}
                        background={new THREE.Color(0xffffff)}/>
                        </mesh>

                    
                               

                <Plane args={[20, 10, 40, 20]} rotation={[Math.PI, Math.PI, 0]} position={[0, 0, -.75]}>
                    <meshBasicMaterial color="lightgray" wireframe />
                 </Plane>


                <CameraRig zoom rotationRange = {{ horizontal: 5, vertical: 5 }}  position = {{ x: 0, y: 0, z: 2 }} lookAt={{x: 0, y: -.15, z: 0}}/>
                <EffectComposer>
                <Bloom
                    intensity={.1}
                    blurPass={undefined}
                    kernelSize={KernelSize.LARGE} 
                    luminanceThreshold={0.9} 
                    luminanceSmoothing={0.025} 
                    mipmapBlur={false} 
                    resolutionX={Resolution.AUTO_SIZE}
                    resolutionY={Resolution.AUTO_SIZE}
                    />                
  
                <ToneMapping
                    adaptive={true} 
                    blendFunction={BlendFunction.NORMAL} 
                    resolution={1024}
                    middleGrey={10.5} 
                    maxLuminance={16.0}
                    averageLuminance={1.0}
                    adaptationRate={1.0} 
                    />
                </EffectComposer>
                
                
                </Suspense>
                </>


    );
}
  
  export default Info;
  