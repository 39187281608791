import BlogList from './Bloglist';
import useFetch from './useFetch';
import { Canvas, useLoader, useFrame } from '@react-three/fiber';
import { Box, MeshPortalMaterial, MeshTransmissionMaterial, MeshRefractionMaterial, MeshWobbleMaterial, OrbitControls, Environment, Reflector, Text, Plane, Cone, useFBO } from '@react-three/drei';
import { useEffect, useRef, useState, Suspense } from 'react';
import * as THREE from 'three';
import { TextureLoader } from 'three';
import CameraRig from './CameraRig';
import { RGBELoader } from 'three-stdlib'

import { ToneMapping, EffectComposer, Bloom } from '@react-three/postprocessing'
import { BlendFunction,  KernelSize, Resolution,BlurPass, Resizer  } from 'postprocessing'
import {Objects} from './Objects';

export const EventsContent = () => {
    return (    
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '15px' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', userSelect: 'none', height:'80vh', width: '100vw', maxWidth:'800px'}}>
                    <h2>Events</h2>
                    <h2> </h2>
                    <iframe
                        src="https://lu.ma/embed/calendar/cal-BaFYeEjYq1HG2Ro/events?lt=light"
                        width="100%"
                        height="80%"
                        frameBorder="0"
                        style={{ border: '1px solid #bfcbda88', borderRadius: '4px' }}
                        allowFullScreen=""
                        aria-hidden="false"
                        tabIndex="0"
                    ></iframe>

                </div>
            </div>
        </div>
    );
};








const Events = () => {
    const { data: blogs, isPending, isError } = useFetch('https://meta-blog-json.onrender.com/blogs');
    const [rotation, setRotation] = useState(0);


    const texture = useLoader(RGBELoader, "sky.hdr")
    const white = useLoader(TextureLoader, 'white.png');
    const normalMap = useLoader(TextureLoader, '315-normal.jpg');
    normalMap.wrapS = THREE.RepeatWrapping;
    normalMap.wrapT = THREE.RepeatWrapping;
    normalMap.repeat.set(2, 2); // Adjust the repetition to your liking



    



    



    return (

            <>
                <color attach="background" args={['white']} />     
                   <Suspense fallback={null}>
                   <Environment files="sky.hdr" />
                   <pointLight intensity={1} position={[0,1,0]} />
                  <ambientLight intensity={.1} />
                

                <mesh scale={[0.35, 0.35, 0.35]} rotation={[Math.PI / 2, Math.PI, 0]}>
                    <Objects/>
                </mesh>



                <Plane args={[20, 10, 40, 20]} rotation={[Math.PI, Math.PI, 0]} position={[0, 0, -.75]}>
                    <meshBasicMaterial color="lightgray" wireframe />
                 </Plane>


                <CameraRig zoom rotationRange = {{ horizontal: 5, vertical: 5 }}  position = {{ x: 0, y: 0, z: 2 }} lookAt={{x: 0, y: -.15, z: 0}}/>
                <EffectComposer>
                <Bloom
                    intensity={.1} 
                    blurPass={undefined}
                    kernelSize={KernelSize.LARGE} 
                    luminanceThreshold={0.9} 
                    luminanceSmoothing={0.025}
                    mipmapBlur={false}
                    resolutionX={Resolution.AUTO_SIZE}
                    resolutionY={Resolution.AUTO_SIZE} 
                    />                
  
                <ToneMapping
                    adaptive={true} 
                    blendFunction={BlendFunction.NORMAL}
                    resolution={1024} 
                    middleGrey={10.5} 
                    maxLuminance={16.0}
                    averageLuminance={1.0} 
                    adaptationRate={1.0} 
                    />
                </EffectComposer>
                
                
                </Suspense>
                </>


    );
}
  
  export default Events;
  